.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 0 50px 0;
  background: linear-gradient(270deg, #414345, #232526);
  color: #ffffff;
  font-family: 'Rubik', sans-serif;

  h1 {
    font-size: 50px;
  }

  h5 {
    color: #57a4fd;
    padding: 15px 0;
  }
  p {
    padding: 5px 0;
    width: 40%;
    text-align: center;
    font-size: 18px;
  }

  .icons {
    padding-top: 10px;
    width: 20%;
    display: flex;
    justify-content: space-around;

    i {
      color: white;
      font-size: 35px;
      padding: 15px;
      margin: 0 10px;
      border: 1px solid white;
      border-radius: 50%;
      transition: 0.5s;
    }

    i:hover {
      background-color: #424446;
      transition: 0.25s;
    }
  }
}
footer {
  background-color: #1c1c1c;
  color: white;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 1000px) {
  .contact-container {
    p {
      width: 80%;
    }
    h5 {
      width: 60%;
      margin: 0 20%;
    }
  }
}
