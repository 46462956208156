@import '~bootstrap/scss/bootstrap';

.nav {
  font-family: 'Lato', sans-serif;
}

.logo {
  width: 50px;
  margin: 0;
  padding: 0;
}

.link-item {
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding-top: 9px;
  transition: 0.75s;
}

.link-item:hover {
  color: #9e9df0;
  transition: 0.25s;
}
