.subProject-container {
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  font-family: 'Rubik', sans-serif;
  color: #3b3b3b;
  background-color: #e8e6ef;

  img {
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
  }

  img:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
  }

  .subProjects {
    max-width: 400px;

    h3 {
      padding: 25px 0 10px 0;
    }

    p {
      padding-top: 10px;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .subProject-container {
    flex-direction: column;
    align-items: center;
    padding: 25px 0;

    img {
      width: 90%;
      margin: 0;
    }

    .subProjects {
      padding: 30px 0;
      width: 90%;
      margin: 0 5%;
    }
  }
}
