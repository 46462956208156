.project-container {
  background-color: #f1f1f1;
}

.project-content-container {
  padding-top: 125px;
  max-width: 900px;
  color: #3b3b3b;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Rubik', sans-serif;

  img {
    max-width: 900px;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
  }

  img:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
  }

  .projects {
    display: flex;
    flex-direction: column;
    // align-items: center;

    h1 {
      padding: 45px 0 20px 0;
      font-size: 60px;
    }

    p {
      padding: 30px 0 150px 0;
      max-width: 800px;
      text-align: justify;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .project-content-container {
    width: 100%;

    .projects {
      align-items: center;

      img {
        width: 90%;
        margin: 0 5%;
      }

      h1 {
        font-size: 40px;
      }

      p {
        width: 90%;
      }
    }
  }
}
