.container-info {
  //   border: pink 1px solid;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 75px 0;

  .intro-text-container {
    max-width: 700px;
    text-align: center;
    font-family: 'Rubik', sans-serif;

    h1 {
      font-size: 75px;
      padding: 50px 0;
    }

    p {
      font-size: 18px;
      width: 90%;
      margin: 0 5%;
    }
    span {
      color: #57a4fd;
    }
  }

  img {
    max-height: 500px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1100px) {
  .container-info {
    flex-direction: column;
  }
  .intro-text-container {
    margin: 35px auto;
  }
}

@media only screen and (max-width: 475px) {
  img {
    height: 100%;
    width: 90%;
  }
}
